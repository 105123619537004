<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <Card :title="'Tambah Obat'">
          <template v-slot:body>
            <Form
              :form="form"
              :route="'medicines'"
            />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/component/medicine/MedicineForm.vue";

export default {
  name: "Add",

  components: {
    Card,
    Form,
  },

  data() {
    return {
      // Form
      form: {
        name: "",
        generic_name: "",
        photo: "/images/default-medicine.svg",
        // photo: null,
        medicine_category_id: "",
        uom_id: "",
        sales_price: '',
        effects: "",
        notes: "",
        minimum_stock: "",
        status: "",
        weight: "",
        high: "",
        wide: "",
        long: "",
        // vendor_id: null,
        // vendor_name: "",
      },
      
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Daftar Obat", route: "/medicine/list" },
      { title: "Tambah Obat" },
    ]);
  },
};
</script>

<style>
</style>